import React, { useEffect, useState } from 'react'
import { BiSave, BiTrash } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { SettingsAside, SettingsHeader } from '../../../components/brand';
import Button from '../../../components/button';
import { brandToggle, camera } from '../../../components/images';
import InputB from '../../../components/input_b';
import { brandCategories } from '../../../data';
import instance from '../../../helpers/axios';
import ButtonLoader from '../../../helpers/button_loader';
import { isValidUrl, validateEmail } from '../../../helpers/helpers';
import Loader from '../../../helpers/loader';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import { FiTrash, FiUpload } from 'react-icons/fi';
import AddBannerModal from '../../../components/brand/add_banner';


const BrandAccountSettings: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state: any) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [brandName, setBrandName] = useState('')
    const [loading, setLoading] = useState(false)
    const [isAdsBanner, setIsAdsBanner] = useState(false)
    const [category, setCategory] = useState('')
    const [website, setWebsite] = useState('')
    const [brand, setBrand] = useState({})
    const [brandid, setBrandid] = useState("")
    const [tel, setTel] = useState('')
    const [email, setEmail] = useState('')
    const [reg, setReg] = useState('')
    const [person, setPerson] = useState('')
    const [file, setFile] = useState<File>()
    const [uploadedFiles, setUploadedFiles] = useState<any[]>()

    const [fileSelected, setFileSelected] = useState<any>('')
    const [selectedUrl, setSelectedUrl] = useState('')
    const [brandColor, setBrandColor] = useState("#0b0149")
    const [fileLoading, setFileLoading] = useState(false)
    const [avatar, setAvatar] = useState('')
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`,
    }

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])

    // get brand profile
    useEffect(() => {
        getProfile();
    }, [])
    const getProfile = () => {
        setLoading(true)
        instance({
            method: "GET",
            url: "/brand/profile",
            headers: headers
        }).then((response) => {
            // console.log(response)
            const c = response.data.data.challenges;
            const b = response.data.data.brand;
            setBrandName(b.brandName)
            setBrandid(b.brandId)

            setCategory(b.brandCategory)
            setBrand(response.data.data.brand);
            setWebsite(b.website);
            if (b.brandColor) setBrandColor(b.brandColor)
            setEmail(b.contactEmail)
            setPerson(b.contactName)
            setTel(b.contactPhoneNumber)
            setAvatar(b.avatar)
            setReg(b.corporateRegistrationNumber)
            getImages(b.brandId)
        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            else if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }

            toast.error(err.response.data.message)
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }
    const getImages = (b = "") => {
        instance({
            method: "GET",
            url: `file?userId=${b == "" ? brandid : b}&fileIntent=advert`,
            headers: headers
        }).then((response) => {
            setUploadedFiles(response.data.data.docs)
        })
        setIsAdsBanner(false)
    }
    const fileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // setFile(e.target.files[0])
        setFileLoading(true)
        let file_ = e.target.files ? e.target.files[0] : null;
        if (file_) {
            var formData = new FormData();
            formData.append("file", file_)

            instance({
                method: 'post',
                data: formData,
                headers: headers,
                url: '/file/upload/profile'
            }).then((response) => {
                console.log(response)
                setSelectedUrl(response.data.data.secureUrl)

            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setFileLoading(false)
            })
            setFile(file_)
            // sessionStorage.setItem('c_file', file_)
            var reader = new FileReader();
            reader.onload = function (event) {
                // The file's text will be printed here
                setFileSelected(event.target?.result)
            };

            reader.readAsDataURL(file_);
        } else {
            setFileSelected("")
        }
    }
    const fileUploadChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        let file_ = e.target.files;
        if (file_) {
            let u = uploadedFiles ? uploadedFiles.length : 0;
            u = file_.length + u;

            if (u >= 3) {
                return toast.error("You cannot upload more than 3 banners")
            }
            for (let i = 0; i < file_.length; i++) {
                const f = file_[i];
                var formData = new FormData();
                formData.append("file", f)
                formData.append("fileIntent", "advert");
                formData.append("sourceType", "advert");
                const reader = new FileReader();
                reader.onload = (e) => {
                    const img = new Image();
                    img.onload = () => {
                        var h = img.height;
                        var w = img.width;
                        if (w != 2732 || h != 758) {
                            toast.error("Banner image dimension must be 2732px by 758px")
                        } else {
                            instance({
                                method: 'post',
                                data: formData,
                                headers: headers,
                                url: `/file/upload/advert-${brandid}`
                            }).then((response) => {

                            }).catch((err) => {
                                console.log(err)
                            }).finally(() => {
                                setFileLoading(false)
                            })
                        }
                    };
                    img.src = e.target?.result as string;
                };

                reader.readAsDataURL(f);

            }
            getImages()
        }
    }

    const deleteFile = (fileid: string) => {
        instance({
            method: "DELETE",
            url: `file`,
            data : {fileUrls : [fileid]},
            headers: headers
        }).then((response) => {
            toast.success("File deleted")
           getImages()
        })
    }

    const updateBrand = (type: string) => {
        if (loading === true) {
            return false;
        }
        let data;
        setLoading(true)

        setTimeout(() => {

            var passed = true;

            if (type == "profile") {

                if (brandName === "") {
                    toast.error("Brand name cannot be empty")
                    passed = false;
                }

                if (website === "" || !isValidUrl(website)) {
                    passed = false;
                    toast.error("Please input a valid website link. Hint : https://example.com")
                }

                if (email === "" || !validateEmail(email)) {
                    passed = false;
                    toast.error("Please input a valid contact email address. Hint : someone@example.com")
                }

                if (tel === "") {
                    passed = false;
                    toast.error("Mobile number is required")
                }

                if (reg === "") {
                    passed = false;
                    toast.error("Company Registration number is  required")
                }

                if (person === "") {
                    passed = false;
                    toast.error("Contact person's name is  required")
                }
                if (category === "") {
                    toast.error("Busienss Category is  required")
                    passed = false;
                }

                data = {
                    "brandName": brandName,
                    // "avatar": "string",
                    "brandCategory": category,
                    // "areaOfIndustry": {},
                    // "about": "string",
                    "corporateRegistrationNumber": reg,
                    // "address": "string",
                    // "country": "string",
                    // "city": "string",
                    "website": website,
                    "contactName": person,
                    "contactPhoneNumber": tel,
                    "contactEmail": email,
                    "brandColor": brandColor
                }
            } else {
                data = {
                    "avatar": selectedUrl,
                }
            }

            if (passed) {
                instance({
                    method: 'PUT',
                    url: '/brand/account',
                    data: data,
                    headers: headers,
                }).then((response) => {
                    toast.success("Saved successfully")
                    // getProfile()
                    window.location.reload()
                    setFileSelected("")
                }).catch((Err) => {
                    console.log(Err)
                    toast.error("Something went wrong, please try again")
                }).finally(() => setLoading(false))
            } else {
                setLoading(false);
            }
        }, 2000);

    }
    const unsetselected = () => {
        setFileSelected('')
    }
    useDocumentTitle('ACCOUNT SETTINGS')

    return (
        <div className='w-full'>
            <ToastContainer />
            <div className="px-[15px] h-100 relative font-primary flex max-w-[1440]">
                <SettingsAside />
                { isAdsBanner &&
                    <AddBannerModal 
                    brandId={brandid}
                    callback={()=>getImages()} close={() => {
                        setIsAdsBanner(false)
                    }} />
                }
                <div className='lg:container sm:mx-auto md:mx-auto flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                    <SettingsHeader title='Account' sub_title='Edit/update your brand details' />
                    <div className="my-2">
                        {
                            fileSelected === "" ?
                                <div className="h- relative">
                                    <label htmlFor='uploadDp' style={{ cursor: 'pointer' }} className="absolute rounded shadow-sm h-[150px] w-[150px] bg-[#00000099] flex justify-center items-center">
                                        <img src={camera} className="h-[24px] object-contain" alt="" />
                                    </label>
                                    <img src={avatar === "" ? brandToggle : avatar} alt="" className='h-[150px] w-[150px] object-contain' />
                                    <input onChange={(e) => fileUploadChange(e)} hidden type="file" name="upload_dp" id="uploadDp" accept='.png,.jpg,.jpeg' />
                                </div>
                                :
                                (
                                    fileLoading ? <Loader /> :
                                        <div className="h- relative">
                                            <img src={fileSelected} alt="" className='h-[150px] w-[150px] object-contain' />
                                            <div className="flex">
                                                <button onClick={unsetselected} className='btn p-2 rounded-[20px] bg-transparent broder-blue text-[12px] flex items-center justify-evenly' >Cancel</button> <div className="mx-2"></div>
                                                <button onClick={() => updateBrand("avatar")} className='btn p-2 rounded-[20px] bg-blue text-yellow text-[12px] flex items-center justify-evenly'>
                                                    {
                                                        loading ? <ButtonLoader /> :
                                                            <div className='flex items-center'>
                                                                <BiSave /> <div className="ml-2"></div> Save
                                                            </div>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                )
                        }

                    </div>
                    <div className="my-[20px]">
                        <InputB placeholder='Brand Name' value={brandName} setValue={setBrandName} type='text' />
                        <InputB pos='top-[50%]' posErr='top-[45%]' prevErr={false} showError={false} err="" isDropDown={true} items={brandCategories} placeholder="Brand category" type="text" value={category} setValue={setCategory} containerClass="mt-[4px]" />
                        <InputB placeholder='Website' value={website} setValue={setBrandName} type='text' />
                        <div className='flex gap-4 mb-[2px] my-[15px] mt-[25px]'>
                            <label htmlFor="" className='text-blue opacity-90'>Brand Color</label>
                            <div className='mb-[2px] '>
                                <input type="color" pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$" id="favcolor" onChange={(e) => setBrandColor(e.target.value)} name="favcolor" value={brandColor} /><br />
                            </div>
                        </div>
                    </div>
                    <div className="my-[30px]">
                        <h6 className="text-blue font-primarybold">Contact Information</h6>
                        <div className="grid lg:grid-cols-2 gap-x-4">
                            <InputB placeholder='Email address' value={email} setValue={setEmail} type='text' />
                            <InputB placeholder='Telephone' value={tel} setValue={setTel} type='text' />
                            <InputB placeholder='Company Reg. No.' value={reg} setValue={setReg} type='text' />
                            <InputB placeholder='Contact Name' value={person} setValue={setPerson} type='text' />
                        </div>
                        <InputB placeholder='Language' value="English" setValue={''} type='text' />
                    </div>

                    <div className="my-[30px]">
                        <div className="flex justify-between">
                            <h6 className="text-blue font-primarybold">Advertisments</h6>
                            <div>
                                <label onClick={()=> {
                                if(uploadedFiles){
                                if(uploadedFiles?.length >= 3){
                                return toast.error("You cannot upload more than 3 banners");
                                }
                                }
                                setIsAdsBanner(true)
                                }
                                } style={{ cursor: 'pointer' }} className="flex gap-2 items-center">
                                    <FiUpload className='text-blue' />
                                    <span>Upload</span>
                                </label>
                            </div>
                        </div>
                        <p className='p-0 m-0 text-[13px]'>Upload up to 3 images to be displayed on your Campaign's page</p>
                        <div className='h-[20px]'></div>
                        <div className="grid grid-cols-2 gap-2">
                            {
                                uploadedFiles?.map((item: any, i) => (
                                    <div className='relative'>
                                        <div className='shadow p-1 absolute right-[3px] top-0 rounded-[100%]'>
                                            <BiTrash  onClick={() => {
                                                deleteFile(item.secureUrl)
                                            }} className='text-white ' />
                                        </div>
                                        <img src={item.secureUrl} alt="" className='w-full cover max-h-[250px]' />
                                    </div>
                                ))
                            }
                            {
                                uploadedFiles?.length == 0 && <div>
                                    <h6>You have no bannner uploaded</h6>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="mt-[30px] flex lg:justify-end justify-center">
                        {/* <Button containerClass='border-blue bg-transparent w-[150px] text-blue' text='Cancel' /> <span className="mx-2"></span> */}
                        <Button loading={loading} onclick={() => updateBrand("profile")} containerClass='bg-blue w-[150px] text-yellow' text='Save' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrandAccountSettings