import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { SuperSEO } from 'react-super-seo'
import instance from '../helpers/axios'
import { useTypedSelector } from '../hooks/useSelector'
import { ActionType } from '../store/actions'
import VideoThumbnail from 'react-video-thumbnail'; // use npm published version
import { video } from '../components/images'


const PostDetails = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const { search } = useLocation();
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState('')
    const [mediaUrl, setMediaUrl] = useState('')
    const [image, setImage] = useState('')

    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "none"
        })
// call the custom function to do the insert

        const search_ = (search.replace('?', '')).split("&")
        console.log(search_)
        // search 0 will be ref
        var ref_ = search_[0].replace('ref=', "")

        // challenge-post/view-with-code/oeBBett?ref=fb

        instance({
            method: "GET",
            url: `challenge-post/view-with-code/${params.shareCode}?ref=${ref_}`,
            headers: headers
        }).then((response) => {
            console.log(response)
            setMediaUrl(response.data.data.media.mediaSecureUrl)
            getChallenge(response.data.data.challenge)
        }).catch((err) => {
            console.log(err)
        })

    }, [])


    const getChallenge = (paramsid: string) => {
        instance({
            method: "POST",
            url: `/challenge/single/${paramsid}/0/0`,
        }).then((response) => {
            setTitle(response.data.data.challenge.title)
            document.querySelector('meta[name="description"]')?.setAttribute("content", response.data.data.challenge.title);
            setDescription(response.data.data.challenge.description)
        }).catch((err) => {
            console.log(err)
        })
    }
    return (
        <>
            {/* <Thumbnail */}
            <div className='hidden'>
            <VideoThumbnail
                cors={true}
                videoUrl={video}
                thumbnailHandler={(thumbnail: any) => setImage(thumbnail)}
                width={120}
                height={80}
            />
            </div>

            {/* <SuperSEO
                title={title}
                description={description}
                lang="en"
                openGraph={{
                    ogImage: {
                        ogImage: image,
                        ogImageAlt: title,
                        ogImageWidth: 1200,
                        ogImageHeight: 630,
                        ogImageType: "image/jpeg",
                    },
                }}
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: image,
                        summaryCardImageAlt: title,
                        summaryCardSiteUsername: description,
                    },
                }}
            /> */}
            <div>PostDetails</div>

        </>
    )
}

export default PostDetails