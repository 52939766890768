import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import { slugify } from "../helpers/helpers";

export function Qr({ qrCode, brandName }: any) {


    const downloadQrCode = (id: string) => {
        var input = document.getElementById(id);
        html2canvas(input!)
            .then((canvas) => {

                var imgData = canvas.toDataURL('image/jpg');
                console.log(imgData)
                const a = document.createElement("a")
                a.href = imgData
                a.download = `${id}.jpg`
                a.click()


            })
    }

    return (
        <>
            <div className='text-center w-[100px]'>
                <div
                    id={`dowload${qrCode}`}
                    className='qrcode bg-white'>
                    <QRCode
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        viewBox={`0 0 256 256`}
                        value={`https://${window.location.host}/${slugify(brandName)}/${qrCode}`} />
                </div>
                <small>
                    <a onClick={() => downloadQrCode(`dowload${qrCode}`)} href='#?' >Download</a>
                </small>
            </div>
        </>
    )
}