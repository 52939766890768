import React, { useState, useEffect, useRef } from 'react'
import { AiOutlineShareAlt } from 'react-icons/ai'
import { BiShare } from 'react-icons/bi'
import { toast, ToastContainer } from 'react-toastify';
import instance from '../../helpers/axios';
import ButtonLoader from '../../helpers/button_loader';
import { useTypedSelector } from '../../hooks/useSelector';
import { facebookSVG, instagramSVG, linkedinSVG, telegramSVG, twitterSVG, whatsappSVG } from '../images'
import { FiUpload } from 'react-icons/fi';
import ReactPlayer from 'react-player';
import ls from 'localstorage-slim'
import ConfirmModal from './ask_modal';

interface iConfirm {
    close: any;
    callback: any;
}

function InstagramUpload(data: iConfirm) {
    const [isLoading, setIsLoading] = useState(false)
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const [formError, setFormError] = useState("")
    const [file, setFile] = useState<any>(null)
    const [mediaSecureUrl, setMediaSecureUrl] = useState<any>(null)
    const [page, setPage] = useState(1)
    const [allPost, setAllPost] = useState<any[]>([])
    const [totalPages, setTotalPages] = useState(1)
    const [showPlayer, setShowPlayer] = useState(false)
    const [playerReady, setPlayerReady] = useState(false)
    const [isPost, setIsPost] = useState(false)

    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }
    useEffect(() => {
        fetchConstant();
    }, [data, page])
    const fetchConstant = () => {
        setIsLoading(true)
        let brandid = ls.get("app_brand", { decrypt: true })
        instance({
            method: "GET",
            url: `/challenge-post/all?brandId=${brandid}&_page=${page}&_limit=20&_ordderBy=createdAt&_order=DESC&contentType=${'video'}`,
            headers: headers
        }).then((response) => {
            console.log("all", response.data)
            let videos = response.data.data.docs;
            videos = videos.map((item: any) => {
                return {
                    thumbnailUrl: item.thumbnailUrl,
                    video: item.media.mediaSecureUrl
                }
            })
            setAllPost(videos);
            setTotalPages(response.data.data.totalPages)
        }).catch((err:any)=> {
            console.log("error", err)
        }).finally(()=> {
        setIsLoading(false)
        })
    }
    const fileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormError("")
        setFile(null)
        // console.log(e.target.files )
        let file_ = e.target.files ? e.target.files[0] : null;
        if (file_) {
            setFile(file_)
            var reader = new FileReader();
            reader.onload = function (event) {
                setMediaSecureUrl(event.target?.result)
            };
            reader.readAsDataURL(file_);
        } else {
            setFile(null)
        }
    }
    return (
        <div>
            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
                <div className="max-h-[300px] min-h-[100px] w-full flex justify-center">
                    <div onClick={() => data.close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                                {
                                    showPlayer
                                        ?
                                        <div className='fixed flex items-center  top-0 left-0 w-full z-[99999] h-[100vh]'>
                                            <div className="max-h-[80vh] min-h-[300px] w-full flex justify-center">
                                                <div onClick={() => setShowPlayer(false)} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                                                <div className='relative'>
                                                    {
                                                        playerReady ? ''
                                                            :
                                                            <div className='absolute top-[48%] z-[3px] left-[45%]'>
                                                                <ButtonLoader />
                                                            </div>
                                                    }

                                                    <div className={`${playerReady ? 'bg-white' : ''} left-[0] z-[49999px]`}>
                                                        <ReactPlayer
                                                            controls={true}
                                                            onReady={() => { setPlayerReady(true) }}
                                                            // url={media.mediaSecureUrl} 
                                                            url={mediaSecureUrl}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }

{
                isPost ? 
                <ConfirmModal
                callback={()=>{}}
                 close={()=>setIsPost(false)} title="Are you sure you want to Post this Video?" description='This action cannot be revert' />
                : ""
            }
                    <div style={{overflowY : "auto"}} className='bg-white h-[100%] max-h-[80vh] shadow-lg rounded-[15px] w-[95%] lg:w-[660px] p-3 relative'>
                        <div className="mt-[10px] mb-[15px] px-3 font-primary ">
                            <div className="" id="">
                                <div className="flex items-center justify-between">
                                <h3 className="text-blue text-uppercase text-[20px] font-bold mb-3">Upload to Instagram</h3>
                                <a className='text-[26px] font-bold text-red-700' 
                                onClick={()=> data.close()} href='#?'>&times;</a>
                                </div>

                                <div className="text">
                                    <p className="text-[14px]">Select a video below</p>
                                </div>

                                <>
                                    <div className="h-[1px] my-3 bg-[#CDE1FF] w-full"></div>
                                    {
                                        isLoading && <p>Loading...</p>
                                    }
                                    {/* <div className=' rounded-[8px] w-full flex items-center py-1'>
                                        <input accept='video/*' onChange={fileUploadChange} type="file" hidden name="uploadVideo" id="uploadFile" />
                                        {
                                            file ? <>
                                                    <div className='relative h-[300px] w-full'>
                                                    <ReactPlayer
                                                        height={'300px'}
                                                        width={"100%"}
                                                        controls={true}
                                                        url={mediaSecureUrl}
                                                    />
                                                    <button type="button" onClick={() => { }} className="btn hover:bg-[red] absolute left-0 top-0 bg-red-700 text-white close-swl">Publish</button>
                                                    <label htmlFor='uploadFile' className="btn absolute right-0 top-0 bg-blue hover:bg-blue text-white close-swl">Change</label>
                                                </div>
                                            </>
                                            :
                                            <label htmlFor='uploadFile' className='flex w-full h-[100px] justify-center items-center flex-col text-center'>
                                                <FiUpload style={{ fontSize: "31px", color: "#7E799F" }} />
                                                {

                                                    <>
                                                        <p className='text-[15px] text-[#7E799F]'>Click to Upload a Video</p>
                                                        {
                                                            formError !== "" && <p className='text-[12px] text-red-700'>{formError}</p>
                                                        }
                                                        {
                                                            file && <>
                                                            </>
                                                        }
                                                    </>
                                                }

                                            </label>
                                        }
                                    </div> */}

                                    <div className='grid gap-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-3'>
                                        {
                                            allPost.map((item, i) => (
                                                <div className='shadow p-2 rounded-[10px] '>
                                                    <img
                                                    onClick={()=> {
                                                        setShowPlayer(true)
                                                        setPlayerReady(false)
                                                        setMediaSecureUrl(item.video)
                                                    }} className='cursor-[pointer] shadow rounded-[10px] w-full h-[150px] md:h-[200px] lg:h-[200px] object-cover' src={item.thumbnailUrl} />
                                                    <div className='h-[10px]'></div>
                                                    <div className='flex items-center gap-2'>
                                                        <a 
                                                         onClick={()=> {
                                                            setIsPost(true)
                                                        }}
                                                        className='text-[12px] text-blue' href='#?'>Post</a>
                                                        <span>|</span>
                                                        <a
                                                        onClick={()=> {
                                                            setShowPlayer(true)
                                                            setPlayerReady(false)
                                                            setMediaSecureUrl(item.video)
                                                        }}
                                                         className='text-[12px] text-blue' href='#?'>Play</a>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            allPost.length == 0 && !isLoading && <p>No post found</p>
                                        }
                                    </div>

                                    <div className="h-[1px] my-4 bg-[#CDE1FF] w-full"></div>
                                </>

                                {/* <button type="button" onClick={()=>data.callback()} className="btn bg-blue text-white close-swl">Confirm</button> */}
                                <div className="mt-3 flex items-center justify-center">
                                    <button type="button" onClick={() => data.close()} className="btn bg-red-700 text-white close-swl">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default InstagramUpload;