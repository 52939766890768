import React from 'react'
import { RxCaretLeft, RxCaretRight } from 'react-icons/rx';
import { Link, useNavigate } from 'react-router-dom';
import { iPagination } from '../models/model';
import ReactPaginate from 'react-paginate';

function Pagination({length, page, limit = 10} : iPagination) {
    const navigate = useNavigate();
    // const allRecords = length + limit
    function GenerateLinks ({length, page} : iPagination) {
        var bucket = [], i = 0;
        while (++i <= length) bucket.push(i);
        return <div>
            {
                bucket.map((item, i)=> (
                    <Link key={item} className={`ml-2 ${page === item ? 'bg-[#0B0149] text-white' : 'bg-white'} rounded shadow-sm p-2 px-3`} to={`?page=${item}`}> {item} </Link>
                ))
            }

        </div>
    }
    
  return (
    <div className='my-3 flex flex-col lg:flex-row items-center justify-between'>
    <p className='text-[#0B014980]'>
        Showing {page == 1 ? 1 : ((page - 1) * limit) + 1} to {page == 1 ? (limit > length ? length : limit) : (((page - 1) * limit) + limit > length ? length : ((page - 1) * limit) + limit ) } of {length} entries
    </p>
    {
        
    }

    {/* <div className='flex items-center'>
        <Link className={`${page == 1 ? 'bg-[#0B014980]' : 'bg-white'} rounded shadow-sm p-2`} to={`?${page == 1 ? '#' : 'page='+(page - 1).toString()}`}> <RxCaretLeft /> </Link>
        {
            Math.ceil(length/limit) > 5 ?  ''
            :
            <GenerateLinks length={Math.ceil(length/limit)} page={page} />
        }
        <Link className={`ml-2 ${Math.ceil(length/limit) === page ? 'bg-[#0B014980]' : 'bg-white'} rounded shadow-sm p-2`} to={`?${Math.ceil(length/limit) === page ? '#' : 'page='+(page + 1).toString()}`}> <RxCaretRight /> </Link>
    </div>
     */}

        <ReactPaginate
        className='flex items-center gap-3'
        pageClassName='rounded shadow-sm p-2 px-3 bg-[#fff]'
        pageLinkClassName={''}
        activeClassName='bg-blue bg_me text-[#fff] font-bold rounded shadow-sm p-2 px-3'
        activeLinkClassName='text-white'
        breakLabel="..."
        
        nextLabel={<RxCaretRight />}
        onPageChange={(value)=>{
            navigate("?page="+(value.selected+1))
        }}
        initialPage={page-1}
        previousClassName={`${page == 1 ? 'bg-[#0B014980]' : 'bg-white'} rounded shadow-sm p-2`}
        nextClassName={`ml-2 ${Math.ceil(length/limit) === page ? 'bg-[#0B014980]' : 'bg-white'} rounded shadow-sm p-2`} 
        pageRangeDisplayed={5}
        pageCount={Math.ceil(length/10)}
        previousLabel={<RxCaretLeft />}
        renderOnZeroPageCount={null}
        />
</div>

  )
}

export default Pagination